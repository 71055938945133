import './header.scss'

export default function Header() {
  return (
    <div className="header">
      <h1>
        Fine Art by Mitra
      </h1>
    </div>
  )
}