import beautifulOrange from './Beautiful Orange-min.JPG'
import blueHue from './Blue Hue-min.JPG'
import coHikeTrail from './CO Hike Trail-min.jpeg'
import fallInRockyMountains from './Fall in Rocky Mountains-min.JPG'
import fruitColorBurst from './Fruit Color Burst-min.JPG'
import grandpasBook from './Grandpa\'s Book-min.JPG'
import lilly from './Lilly-min.jpg'
import loneBoat from './Lone Boat-min.JPG'
import loneWaterLilly from './Lone Water Lilly-min.JPG'
import milkPitcher from './Milk Pitcher-min.jpg'
import pinkRose from './Pink Rose-min.jpg'
import theWateringCan from './wateringCan.JPG'
import theLake from './The Lake-min.JPG'
import toyFox from './Toy fox-min.JPG'
import whiteOrchids from './White Orchids-min.JPG'
import grayHue from './Gray Hue-min.JPEG'
import poppyFlower from './Poppy Flower.jpg'
import snowkids from './Snowkids.jpeg'
import poenies from './Poenies.jpeg'
import tullips from './Tullips.jpeg'
import pedarJoon from './Pedar Joon.jpeg'
import persiandancer from './persian dancer.jpeg'

export const paintingsList = [
  {
    src: blueHue,
    title: 'Blue Hue',
    size: '9 x 12',
    medium: 'Oil on Canvas',
    description: '',
    cost: '50',
  },
  {
    src: grayHue,
    title: 'Gray Hue',
    size: '9 x 9',
    medium: 'Oil on Canvas',
    description: '',
    cost: '50',
  },
  {
    src: fruitColorBurst,
    title: 'Fruit Color Burst',
    size: '9 x 12',
    medium: 'Oil on Board',
    description: '',
    cost: '100',
  },
  {
    src: theWateringCan,
    title: 'The Watering Can',
    size: '9 x 12',
    medium: 'Oil on Board',
    description: '',
    cost: '100',
  },
  {
    src: milkPitcher,
    title: 'Milk Pitcher',
    size: '12 x 16',
    medium: 'Oil on Canvas',
    description: '',
    cost: '200',
    featured: true,
  },
  {
    src: grandpasBook,
    title: 'Grandpa\'s Book',
    size: '12 x 16',
    medium: 'Oil on Canvas',
    description: '',
    cost: '300',
  },
  {
    src: pinkRose,
    title: 'Pretty Pink',
    size: '11 x 4',
    medium: 'Oil on Board',
    description: '',
    cost: '250',
  },
  {
    src: whiteOrchids,
    title: 'Orchids',
    size: '16 x 20',
    medium: 'Oil on Canvas',
    description: '',
    cost: '450',
  },
  {
    src: toyFox,
    title: 'Toy Fox',
    size: '16 x 20',
    medium: 'Oil on Canvas',
    description: '',
    cost: '450',
  },
  {
    src: loneBoat,
    title: 'Lone Boat',
    size: '16 x 20',
    medium: 'Oil on Canvas',
    description: '',
    cost: '250',
    featured: true,
  },
  {
    src: theLake,
    title: 'The Lake',
    size: '12 x 16',
    medium: 'Oil on Canvas',
    description: '',
    cost: '200',
  },
  {
    src: coHikeTrail,
    title: 'Colorado Hike',
    size: '16 x 20',
    medium: 'Oil on Canvas',
    description: '',
    cost: '200',
  },
  {
    src: fallInRockyMountains,
    title: 'Rocky Mountain Fall',
    size: '24 x 30',
    medium: 'Oil on Canvas',
    description: '',
    cost: '1200',
  },
  {
    src: beautifulOrange,
    title: 'Orange Lilly',
    size: '16 x 20',
    medium: 'Oil on Canvas',
    description: '',
    cost: '450',
  },
  {
    src: loneWaterLilly,
    title: 'Lone Water Lilly',
    size: '16 x 20',
    medium: 'Oil on Hand-Made Canvas',
    description: '',
    cost: '450',
    featured: true,
  },
  {
    src: lilly,
    title: 'White Lilly',
    size: '16 x 20',
    medium: 'Oil on Hand-Made Canvas',
    description: '',
    cost: '1000',
  },
  {
    src: poppyFlower,
    title: 'Pretty Poppy',
    size: '16 x 20',
    medium: 'Oil on Canvas',
    description: '',
    cost: '450',
  },
  {
    src: snowkids,
    title: 'Happy in Snow',
    size: '16 x 20',
    medium: 'Oil on Canvas',
    description: '',
    cost: '500',
  },
  {
    src: poenies,
    title: 'Poenies',
    size: '15 x 20',
    medium: 'Oil on Canvas',
    description: '',
    cost: '500',
  },{
    src: tullips,
    title: 'Tulips',
    size: '16 x 20',
    medium: 'Oil on Canvas',
    description: '',
    cost: '500',
  },{
    src: pedarJoon,
    title: 'Miss you Daddy',
    size: '30 x 40',
    medium: 'Oil on Canvas',
    description: '',
    cost: 'NFS',
  },{
    src: persiandancer,
    title: 'Persian Dancer',
    size: '24 x 30',
    medium: 'Oil on Canvas',
    description: '',
    cost: '1500',
  },
]
